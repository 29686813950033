<template>
  <div>
    <organization-chart :datasource="ds"></organization-chart>
  </div>
</template>

<script>
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import {mapActions} from "vuex";

export default {
  components: {
    OrganizationChart
  },
  data() {
    return {
      ds: {}
    }
  },
  mounted() {
    this.list_of_officer()
      .then(response => {
        this.ds=response.data[0]
        console.log(response.data[0])
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('organization_history', ['list_of_officer']),
  },
}
</script>

<style scoped>

</style>
